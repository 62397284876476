import { SimState, TRIGGER_STATE } from '../actions/state'
import { AnyAction } from 'redux'

type InitialState = {
  currentState: SimState;
}
const initialState: InitialState = {
  currentState: SimState.INIT
};

function setCurrentState(state: InitialState = initialState, action: AnyAction) {
  if (action.type === TRIGGER_STATE)
    return {
      ...state,
      currentState: action.payload
    };

  return state;
}

export default setCurrentState;