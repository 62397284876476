import React, {Component} from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

type SpeedProps = {
  speed: number;
  transitionDuration: number;
}

type SpeedState = {
  previousSpeed: number;
  targetSpeed: number;
  transitionDuration: number;
  currentSpeed: number;
  updateIncrement: number;
}

type Props = WithTranslation & SpeedProps;

class Speed extends Component<Props, SpeedState> {
  clockDelay = 1;
  updateInterval = 500;
  private startedAt: number = Date.now();

  constructor(props: Props){
    super(props);
    this.state = {
      previousSpeed: 0,
      targetSpeed: this.props.speed,
      transitionDuration: this.props.transitionDuration,
      updateIncrement: this.props.transitionDuration > 0 ? this.getUpdateIncrement(0, this.props.speed, this.props.transitionDuration) : 0,
      currentSpeed: this.props.transitionDuration > 0 ? 0 : this.props.speed
    }

    this.updateCurrentSpeed = this.updateCurrentSpeed.bind(this);
    this.getUpdateIncrement = this.getUpdateIncrement.bind(this);
  }

  componentDidMount(){
    requestAnimationFrame(this.updateCurrentSpeed);
  }

  updateCurrentSpeed(){
    if(this.state.currentSpeed === this.state.targetSpeed
      || this.state.updateIncrement === 0){
        requestAnimationFrame(this.updateCurrentSpeed);
        return;
      }

    var currentTime = Date.now();
    if(currentTime - this.startedAt < this.updateInterval){
      requestAnimationFrame(this.updateCurrentSpeed);
      return;
    }

    var skippedFramesAnimations = Math.floor((currentTime - this.startedAt) / this.updateInterval);
    this.startedAt = currentTime - ((currentTime - this.startedAt) % this.updateInterval);


    this.setState((prevState) => {return {...prevState,
      currentSpeed: this.state.updateIncrement > 0 ?
        (prevState.currentSpeed + (this.state.updateIncrement * skippedFramesAnimations)) > this.state.targetSpeed ?
        this.state.targetSpeed : prevState.currentSpeed + (this.state.updateIncrement * skippedFramesAnimations)
        :
        (prevState.currentSpeed + (this.state.updateIncrement * skippedFramesAnimations)) < this.state.targetSpeed ?
        this.state.targetSpeed : prevState.currentSpeed + (this.state.updateIncrement * skippedFramesAnimations)
    }}, () => requestAnimationFrame(this.updateCurrentSpeed));
  }

  getUpdateIncrement(currentSpeed: number, targetSpeed: number, transitionDuration: number): number{
    let speedDelta = targetSpeed - currentSpeed;
    let amountOfUpdates = (transitionDuration*1000) / this.updateInterval;
    return speedDelta / amountOfUpdates;
  }

  componentDidUpdate(prevProps: Props){
    if(this.props.speed !== prevProps.speed){
      this.startedAt = Date.now();
      this.setState((prevState) => {return {
        ...prevState,
        currentSpeed: this.props.transitionDuration > 0 ? prevState.targetSpeed : this.props.speed,
        previousSpeed: prevState.targetSpeed,
        targetSpeed: this.props.speed,
        updateIncrement: this.props.transitionDuration > 0 ? this.getUpdateIncrement(prevState.targetSpeed, this.props.speed, this.props.transitionDuration) : 0,
        transitionDuration: this.props.transitionDuration
      }});
    }
  }

  render(){
    const { t } = this.props;
    return(
      <div className="current-speed">
        <div className="speed">
          {Math.round(this.state.currentSpeed)}
        </div>
        <div className="speed-unit">
          {t('speed unit')}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Speed);
