import { CarDataAction, CarDataPayload, BlinkerAction, SpeedAction, MaxSpeedAction, RoadTypeAction, VehicleDetectionAction,
   UPDATE_CAR_DATA, GpsInstructionAction, GpsEtaAction, BatteryLevel, MapViewerAction} from '../actions/carData'

type InitialState = {
  blinker:BlinkerAction,
  speed:SpeedAction,
  maxSpeed:MaxSpeedAction,
  roadType:RoadTypeAction,
  vehicleDetections: Array<VehicleDetectionAction>,
  gpsInstruction: GpsInstructionAction,
  gpsEta: GpsEtaAction,
  batteryLevel: BatteryLevel,
  mapViewer: MapViewerAction
}

const initialState: InitialState = {
  blinker:{
    actionId: -1,
    direction: "stop"
  },
  speed:{
    actionId: -1,
    targetSpeed: 0,
    transitionDuration: 0
  },
  maxSpeed:{
    actionId: -1,
    maxSpeed: "50"
  },
  roadType:{
    actionId: -1,
    laneAmount: 1,
    oppositeLaneAmount: 1,
    currentLane: 1
  },
  vehicleDetections: [],
  gpsInstruction:{
    actionId: -1,
    type: "straight",
    distance: 10000, //Meter
    duration: 2*60*1000 //Time for distance decrease
  },
  gpsEta:{
    actionId: -1,
    duration: 2*60*1000,
    distance: 3500
  },
  batteryLevel:{
    actionId: -1,
    level: 100
  },
  mapViewer: {
    actionId: -1,
    segmentIndex: 0,
    enforceDuration: 0,
    autoIncrement: 0,
    end: false
  }
};

function updateCarData(state = initialState, action: any) {
  var currentAction = action.payload as CarDataPayload;
  if (action.type === UPDATE_CAR_DATA)
    switch(currentAction.action){
      case CarDataAction.BLINKER:
        return processBlinker(state, currentAction.parameters as BlinkerAction);
      case CarDataAction.MAX_SPEED:
        return processMaxSpeec(state, currentAction.parameters as MaxSpeedAction);
      case CarDataAction.ROAD_TYPE:
        return processRoadType(state, currentAction.parameters as RoadTypeAction);
      case CarDataAction.SPEED:
        return processSpeed(state, currentAction.parameters as SpeedAction);
      case CarDataAction.VEHICLE_DETECTION:
        return processVehicleDetection(state, currentAction.parameters as VehicleDetectionAction);
      case CarDataAction.GPS_INSTRUCTION:
        return processGpsInstruction(state, currentAction.parameters as GpsInstructionAction);
      case CarDataAction.GPS_ETA:
        return processGpsEta(state, currentAction.parameters as GpsEtaAction);
      case CarDataAction.BATTERY_LEVEL:
        return processBatteryLevel(state, currentAction.parameters as BatteryLevel);
      case CarDataAction.MAP_VIEWER:
        return processMapViewerAction(state, currentAction.parameters as MapViewerAction);
    }
  return state;
}

function processBlinker(state: InitialState, action: BlinkerAction): InitialState{
  if(state.blinker.actionId < action.actionId)
    return {
      ...state,
      blinker: action
    }
  else
    return state;
}

function processMaxSpeec(state: InitialState, action: MaxSpeedAction): InitialState{
  if(state.maxSpeed.actionId < action.actionId)
    return {
      ...state,
      maxSpeed: action
    }
  else
    return state;
}

function processRoadType(state: InitialState, action: RoadTypeAction): InitialState{
  if(state.roadType.actionId < action.actionId)
    return {
      ...state,
      roadType: action
    }
  else
    return state;
}

function processSpeed(state: InitialState, action: SpeedAction): InitialState{
  if(state.speed.actionId < action.actionId)
    return {
      ...state,
      speed: action
    }
  else
    return state;
}

function processVehicleDetection(state: InitialState, action: VehicleDetectionAction): InitialState{
  return state;
}

function processGpsInstruction(state: InitialState, action: GpsInstructionAction): InitialState{
  if(state.gpsInstruction.actionId < action.actionId)
    return {
      ...state,
      gpsInstruction: action
    }
  else
    return state;
}

function processGpsEta(state: InitialState, action: GpsEtaAction): InitialState{
  if(state.gpsEta.actionId < action.actionId)
    return {
      ...state,
      gpsEta: action
    }
  else
    return state;
}

function processBatteryLevel(state: InitialState, action: BatteryLevel): InitialState{
  if(state.batteryLevel.actionId < action.actionId && action.level <= 100)
    return{
      ...state,
      batteryLevel: action
    }
  else return state;
}

function processMapViewerAction(state: InitialState, action: MapViewerAction): InitialState{
  if(state.mapViewer.actionId < action.actionId){
    return{
      ...state,
      mapViewer: action
    }
  }
  else return state;
}

export default updateCarData;