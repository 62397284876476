import React, {Component} from 'react';
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next';
import { SimState } from '../../actions/state';
import { RootState } from '../../store/store';
import { CavInfoMode } from '../hmi/cav-info/cavinfo';
import { generateLog, Type } from '../../actions/log';

type OutroProps = {
  mode: CavInfoMode,
  privacy: string | null,
  surveyId: string
}

type AggregatedProps = PropsFromRedux & WithTranslation & OutroProps;

function mapStateToProps(state: RootState) {
  return {
    currentState: state.setCurrentState.currentState as SimState,
    surveyId: state.handleLog.surveyId as string
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    dispatch: (action: any) => { dispatch(action) }
  }
}

class Outro extends Component<AggregatedProps> {
  constructor(props: AggregatedProps){
    super(props);
    this.dismissOutro = this.dismissOutro.bind(this);
  }

  componentDidMount(){
    this.props.dispatch(generateLog({surveyId: this.props.surveyId, timestamp: new Date().toString(), type: Type.SIM_END, value: "" }));
  }

  dismissOutro(){
    this.props.dispatch(generateLog({surveyId: this.props.surveyId, timestamp: new Date().toString(), type: Type.BUTTON_QUIT, value: "" }));
    window.location.href = "https://www.soscisurvey.de/HMI_trust/?q=post&survey_id="
      + this.props.surveyId + "&group=" + this.props.mode + "&priv=" + this.props.privacy;
  }

  render(){
    const { t } = this.props;
    return (
      <div className="outro-modal-holder">
        <div className="outro-modal">
          <h2>{t('outro modal title')}</h2>
          <div dangerouslySetInnerHTML={{ __html: t('outro modal content') }} />
          <button onClick={this.dismissOutro}>{t('outro modal ready button')}</button>
        </div>
      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>
export default withTranslation()(connector(Outro));