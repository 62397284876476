
export const GENERATE_LOG = "GENERATE_LOG";
export const SET_SURVEY_ID = "SET_SURVEY_ID";
export const CLEAR_LOGS = "CLEAR_LOGS";

export enum Type {
  BUTTON_START = "BUTTON_START",
  SIM_START = "SIM_START",
  SIM_END = "SIM_END",
  BUTTON_QUIT = "BUTTON_QUIT",
  BUTTON_NOTIFICATION = "BUTTON_NOTIFICATION",
  BUTTON_DESCRIPTION = "BUTTON_DESCRIPTION",
  BUTTON_TTS = "BUTTON_TTS",
  ASSIGNED_GROUP = "ASSIGNED_GROUP"
}

export type Log = {
  surveyId: string,
  timestamp: string,
  type: Type,
  value: string
}

export function generateLog(payload: Log){
  return {type: GENERATE_LOG, payload: payload};
}

export function clearLogs(payload: Array<Log>){
  return {type: CLEAR_LOGS, payload: payload};
}

export function setSurveyId(payload: string){
  return {type: SET_SURVEY_ID, payload: payload};
}