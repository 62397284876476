import React, {Component} from "react";
import { mapboxToken} from "../../api/global";
import mapboxgl, {Map, GeoJSONSource} from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from "../../store/store";
import 'mapbox-gl/dist/mapbox-gl.css';
import {FeatureCollection, GeoJsonProperties, LineString} from 'geojson';
var coordinates: number[][] = require('../../data/route.json');
var coordinatesInit: number[][] = require('../../data/routeInit.json');

mapboxgl.accessToken = mapboxToken;

type MapViewerProps = {
    speed: number;
    segmentIndex: number;
    lastIndex: number;
}

type MapViewerState = {
    speed: number;
    segmentIndex: number;
    lastIndex: number;
    autoIncrement: number;
    enforceDuration: number;
    final: boolean;
}

type Props = MapViewerProps & PropsFromRedux;

function mapStateToProps(state: RootState){
    return {
        currentState: state.setCurrentState.currentState,
        event: state.setCurrentEvent.event,
        speed: state.updateCarData.speed.targetSpeed,
        segmentIndex: state.updateCarData.mapViewer.segmentIndex,
        autoIncrement: state.updateCarData.mapViewer.autoIncrement,
        enforceDuration: state.updateCarData.mapViewer.enforceDuration,
        end: state.updateCarData.mapViewer.end
    }
}

class MapViewer extends Component<Props, MapViewerState>{
    private mapContainer: HTMLElement | null | undefined = undefined;
    private map: Map | undefined;

    private geojson : FeatureCollection<LineString, GeoJsonProperties> = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: [coordinates[0]]
                },
                'properties': {}
            }
        ]
    }

    private frameRate = 100;
    private animationCounter = 0;
    private lineCoordinates: number[][] = [];
    private startedAt: number = Date.now();

    constructor(props: Props){
        super(props);
        console.log(coordinates[0]);
        this.state = {
            segmentIndex: this.props.segmentIndex,
            lastIndex: this.props.segmentIndex-1,
            speed: this.props.speed,
            autoIncrement: this.props.autoIncrement,
            enforceDuration: this.props.enforceDuration,
            final: false
        }

        this.computeAnimation = this.computeAnimation.bind(this);
        this.animateLine = this.animateLine.bind(this);
        this.renderWebFrame = this.renderWebFrame.bind(this);
        this.getMissingFrames = this.getMissingFrames.bind(this);
    }

    componentDidUpdate(prevProps: Props){
        if(this.props.speed !== prevProps.speed){
            this.setState((prevState) => {return{...prevState, speed: this.props.speed}});
        }

        if(this.props.segmentIndex !== prevProps.segmentIndex){
            if(this.props.end){
                this.map?.removeLayer('route');
                this.map?.removeLayer('line-animation');
                this.setState((prevState) => {return{...prevState, final: true,}});
                return;
            }
            this.setState((prevState) => {return{...prevState, lastIndex: prevProps.segmentIndex,
                segmentIndex: this.props.segmentIndex, autoIncrement: this.props.autoIncrement,
                enforceDuration: this.props.enforceDuration}}, this.computeAnimation);
        }
    }

    componentDidMount(){
        this.map = new mapboxgl.Map({
            container: this.mapContainer === undefined || this.mapContainer === null ? "" : this.mapContainer,
            style: 'mapbox://styles/jeremyrd/cl3bmxcyl00b614qvaxf5wwoa',
            center: [coordinates[0][0], coordinates[0][1]],
            zoom: 14.3,
            interactive: false
        });

        this.map.scrollZoom.disable();

        this.map.on('load', () => {
            this.map?.addSource('route', {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': coordinatesInit
                    }
                }
            });

            this.map?.addLayer({
                'id': 'route',
                'type': 'line',
                'source': 'route',
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': '#26abff',
                    'line-width': 8
                }
            });

            this.map?.addSource('line', {
                'type': 'geojson',
                'data': this.geojson
            });

            // add the line which will be modified in the animation
            this.map?.addLayer({
                'id': 'line-animation',
                'type': 'line',
                'source': 'line',
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': '#777',
                    'line-width': 5,
                    'line-opacity': 0.8
                }
            });

            this.computeAnimation();
        });
    }

    computeAnimation(){
        if(this.state.final){
            return;
        }

        var index = this.state.segmentIndex;
        var lastIndex = this.state.lastIndex;

        if(index >= 63 && this.map !== undefined){
            (this.map?.getSource('route') as GeoJSONSource).setData({
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'LineString',
                            coordinates: coordinates
                        },
                        'properties': {}
                    }
                ]
            });
        }

        // If we want to trigger the road after the clicking on the ready button, we need to change the default to 0
        if(index === 0 || this.state.speed === 0) return;

        this.lineCoordinates = [];
        var instantJumps = 0;

        while(index - lastIndex > 1){
            this.lineCoordinates.push([coordinates[lastIndex][0], coordinates[lastIndex][1]])
            lastIndex++;
            instantJumps++;
        }

        var remainingIncrement = 1 + this.state.autoIncrement;
        var totalLength = 0;
        for(let i = 0; i < remainingIncrement; i++){
            totalLength += this.calculateLength(coordinates[index + i][1], coordinates[index + i - 1][1], coordinates[index + i][0], coordinates[index + i - 1][0]);
        }

        while(remainingIncrement > 0){
            var length = this.calculateLength(coordinates[index][1], coordinates[index-1][1], coordinates[index][0], coordinates[index-1][0]);
            var lengthRatio = length / totalLength;

            var latDiff = coordinates[index][0] - coordinates[index-1][0];
            var lonDiff = coordinates[index][1] - coordinates[index-1][1];
            var time = this.state.enforceDuration > 0 ? this.state.enforceDuration : (length / this.state.speed * 3600 * 1000); // Time in ms
            time = time * lengthRatio;

            var segments = time / this.frameRate;
            var sfLat = latDiff / segments;
            var sfLon = lonDiff / segments;

            var i = 0;
            var j = 0;

            while(Math.abs(i) < Math.abs(latDiff) || Math.abs(j) < Math.abs(lonDiff)){
                this.lineCoordinates.push([coordinates[index-1][0] + i, coordinates[index-1][1] + j]);

                if(Math.abs(i) < Math.abs(latDiff)) i += sfLat;
                if(Math.abs(j) < Math.abs(lonDiff)) j += sfLon;
            }
            index += 1
            remainingIncrement -= 1;
        }

        /*if(index >= 59 && this.map !== undefined){
            this.map.zoomTo(15);
        }*/

        this.animationCounter = 0;
        this.animateLine(instantJumps);
        this.startedAt = Date.now()
        this.renderWebFrame();
    }

    renderWebFrame(){
        if(this.state.final){
            return;
        }
        var missingFrames = this.getMissingFrames();
        if (missingFrames > 0 && this.animationCounter < this.lineCoordinates.length){
            while(missingFrames > 0 && this.animationCounter < this.lineCoordinates.length){
                this.addCurrentAnimarionToGeoJson();
                missingFrames--;
            }
            (this.map?.getSource('line') as GeoJSONSource).setData(this.geojson);
            if (this.animationCounter < this.lineCoordinates.length)
                this.centerMapOnLineCoordinates(this.animationCounter);
            else
                this.centerMapOnLineCoordinates(this.lineCoordinates.length-1);
        }

        if(this.animationCounter < this.lineCoordinates.length)
            requestAnimationFrame(this.renderWebFrame);
    }

    centerMapOnLineCoordinates(index: number){
        this.map?.setCenter([this.lineCoordinates[index][0], this.lineCoordinates[index][1]])
    }

    addCurrentAnimarionToGeoJson(){
        if(this.animationCounter < this.lineCoordinates.length){
            this.geojson.features[0].geometry.coordinates.push(this.lineCoordinates[this.animationCounter]);
            this.animationCounter++;
        }
    }

    getMissingFrames(): number{
        var elapsedFramesSinceStart = Math.ceil((Date.now() - this.startedAt) / this.frameRate);
        return elapsedFramesSinceStart - this.animationCounter + 1;
    }

    animateLine(instantJumps: number){
        if(this.animationCounter < this.lineCoordinates.length){
            while(instantJumps >= 0){
                this.addCurrentAnimarionToGeoJson();
                instantJumps--;
            }
            (this.map?.getSource('line') as GeoJSONSource).setData(this.geojson);
            if(this.animationCounter -1 > 0 && this.animationCounter - 1 < this.lineCoordinates.length)
                this.centerMapOnLineCoordinates(this.animationCounter);
        }
    }

    // Returns lenght in Kms
    calculateLength(lat1 : number, lat2: number, lon1: number, lon2: number): number{
        var earthRadius = 6371;
        var dLat = this.deg2rad(lat2 - lat1);
        var dLon = this.deg2rad(lon2 - lon1);

        var a = Math.sin(dLat/2) * Math.sin(dLat/2)
            + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2))
            * Math.sin(dLon/2) * Math.sin(dLon/2);

        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        return earthRadius * c;
    }

    deg2rad(deg: number): number{
        return deg * (Math.PI / 180);
    }

    render() {
        return(
            <div className={this.props.end ? "is-reached" : ""}>
                {this.props.end ?
                <div className="map-destination-reached">
                    <img src="icons/reached.svg"/>
                </div>
                :
                null}
                <div ref={(el): void => {this.mapContainer = el;}} className='map-container' />
            </div>
        )
    }
}

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(MapViewer);