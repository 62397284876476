import { GENERATE_LOG, Log, SET_SURVEY_ID, CLEAR_LOGS } from '../actions/log'

type InitialState = {
  logs: Array<Log>;
  surveyId: string;
}
const initialState: InitialState = {
  logs: new Array<Log>(),
  surveyId: "not-init"
};

export function handleLog(state = initialState, action: any){
  switch(action.type){
    case GENERATE_LOG:
      return addNewLog(state, action);
    case CLEAR_LOGS:
      return clearLogs(state, action);
    case SET_SURVEY_ID:
      return setSurveyId(state, action);
    default:
      return state;
  }
}

function addNewLog(state: InitialState, action: any) {
  (action.payload as Log).surveyId = state.surveyId;
  return {
    ...state,
    logs: [...state.logs, action.payload]
  };
}

function clearLogs(state : InitialState, action: any){
  var logs = (action.payload as Array<Log>);
  return {
    ...state,
    logs: state.logs.filter((item, index) => !logs.includes(item))
  };
}

function setSurveyId(state = initialState, action: any){
  return {
    ...state,
    surveyId: action.payload
  }
}