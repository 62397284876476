import React, {Component} from "react";
import {withTranslation, WithTranslation} from 'react-i18next';

type BatteryProps = {
    level: number;
}

type BatteryState = {
    level: number;
}

type Props = WithTranslation & BatteryProps;

class Battery extends Component<Props, BatteryState> {
    constructor(props: Props){
        super(props);
        this.state = {
            level: this.props.level
        }
    }

    componentDidUpdate(prevProps: Props){
        if(prevProps.level !== this.props.level){
            this.setState((prevState) => {
                return {...prevState, level: this.props.level}
            });
        }
    }

    renderBatteryLevel(){
        var batteryLvlElem = document.getElementsByClassName('battery-level')[0] as HTMLDivElement;
        if(batteryLvlElem === undefined) return

        batteryLvlElem.style.width = this.props.level + "%";

        if(this.props.level > 30) batteryLvlElem.style.backgroundColor = '#30b455';
        else if(this.props.level > 10) batteryLvlElem.style.backgroundColor = '#efaf13';
        else batteryLvlElem.style.backgroundColor = '#e81309';
    }

    render(){
        return(
            <div className="battery">
                <div className="battery-level"></div>
                {this.renderBatteryLevel()}
            </div>
        )
    }
}

export default withTranslation()(Battery);