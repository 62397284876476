const DEBUG = false;

const baseEndpointProd = "https://pilotservice.pascal-project.eu/lab-experiment";
const baseEndpointDebug = "http://localho.st:8080/lab-experiment";

const logEndpointProd = "/log";
const logEndpointDebug = "/debug-log";

const groupAssignationEndpointProd = "/assign-group";
const groupAssignationEndpointDebug = "/assign-group";

export const logEndpoint = DEBUG ? logEndpointDebug : logEndpointProd;
export const baseEndpoint = DEBUG ? baseEndpointDebug : baseEndpointProd;
export const groupAssignationEndpoint = DEBUG ? groupAssignationEndpointDebug : groupAssignationEndpointProd;

// export const mapboxToken = "pk.eyJ1IjoiYWxlbWFsIiwiYSI6ImNreWU0dGRkNzA4Nmkyd2x1OW5wYmc1bW8ifQ._ydhUO7TvDOLh7_LvpDXcw";
export const mapboxToken = "pk.eyJ1IjoiamVyZW15cmQiLCJhIjoiY2p4b3BwbmtkMDhzNTNpbW9pOGU2YWZ4MSJ9.28Py-c0LOhb74RvUbfBcuQ";
export const jwtToken = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJQQXNDQUwtQWRtaW4iLCJleHAiOjE2NjA2NTQzNjMsImlhdCI6MTY0NDU4Mzk2M30.xVmTOqwIv7rYMLx5Irixr2Db-M86L6P5wbS9jVCf6sv1GRAI8gy-njR29KgjWEiZViXTO-z-OTk71_Boimi4Yg";