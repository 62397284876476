import React, {Component} from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

type MaxSpeedProps = {
  maxSpeed: "30"|"50"|"70"|"80"|"90"|"110"|"120"|"130"|"unl";
}

type MaxSpeedState = {
  maxSpeed: "30"|"50"|"70"|"80"|"90"|"110"|"120"|"130"|"unl";
}

type Props = WithTranslation & MaxSpeedProps;

class MaxSpeed extends Component<Props, MaxSpeedState> {

  constructor(props: Props){
    super(props);
    this.state = {
      maxSpeed: this.props.maxSpeed
    }
  }

  componentDidUpdate(prevProps: Props){
    if(this.props.maxSpeed !== prevProps.maxSpeed){
      this.setState((prevState) => {return {
        ...prevState,
        maxSpeed: this.props.maxSpeed
      }});
    }
  }

  componentWillUnmount() {
  }

  render(){
    return(
      <div className="max-speed">
        <div className="sign">
          {this.state.maxSpeed}
        </div>
      </div>
    );
  }
}

export default withTranslation()(MaxSpeed);
