
export const TRIGGER_STATE = "TRIGGER_STATE";

export enum SimState {
  INIT = "INIT",
  PLAY = "PLAY",
  PAUSE = "PAUSE",
  FINISHED = "FINISHED"
}

export function triggerState(payload: SimState){
  return {type: TRIGGER_STATE, payload: payload};
}