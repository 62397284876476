import { SimEvent, TRIGGER_EVENT } from '../actions/event'

type InitialState = {
  event: SimEvent | null;
}
const initialState: InitialState = {
  event: SimEvent.NONE
};

function setCurrentEvent(state = initialState, action: any) {
  if (action.type === TRIGGER_EVENT)
    return {
      ...state,
      event: action.payload
    };

  return state;
}

export default setCurrentEvent;