import React, {Component} from 'react';
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next';
import { RootState } from '../../../store/store';
import { SimEvent } from '../../../actions/event';
import { generateLog, Type } from '../../../actions/log';

export enum CavInfoMode {
  CUSTOMIZE = "CUSTOMIZE",
  LOWINFO = "LOWINFO",
  HIGHINFO = "HIGHINFO"
}

type CavInfoProps = {
  mode: CavInfoMode
}

type CavInfoState = {
  mode: CavInfoMode,
  showWarning: boolean,
  showDescription: boolean,
  ttsEnabled: boolean,
  event: SimEvent,
  currentSubtitleDisplayed: string
}

function mapStateToProps(state: RootState) {
  return {
    currentState: state.setCurrentState.currentState,
    event: state.setCurrentEvent.event,
    surveyId: state.handleLog.surveyId
  }
}

type Props = CavInfoProps & WithTranslation & PropsFromRedux;

class CavInfo extends Component<Props, CavInfoState> {
  private audio: any;

  private readingDescription = false;

  private currentSubtitle: any;
  private startedSubtitleAt: any;
  private subtitleIndex=0;

  constructor(props: Props){
    super(props);
    this.state = {
      event: SimEvent.NONE,
      mode: this.props.mode,
      showWarning: true,
      ttsEnabled: true,
      showDescription: this.props.mode === CavInfoMode.HIGHINFO || this.props.mode === CavInfoMode.CUSTOMIZE,
      currentSubtitleDisplayed: ""
    }

    this.toggleWarning = this.toggleWarning.bind(this);
    this.toggleDescription = this.toggleDescription.bind(this);
    this.getEventPrependTranslationValue = this.getEventPrependTranslationValue.bind(this);
    this.getEventText = this.getEventText.bind(this);
    this.getDescriptionText = this.getDescriptionText.bind(this);
    this.toggleTts = this.toggleTts.bind(this);
    this.displayCorrectSubtitle = this.displayCorrectSubtitle.bind(this);
  }

  getEventPrependTranslationValue(event: SimEvent){
    switch(event){
      case SimEvent.START_DRIVE:
        return "event start drive";
      case SimEvent.U_TURN:
        return "event u turn";
      case SimEvent.TRAFFIC_JAM_REROUTE:
        return "event traffic jam";
      case SimEvent.ROAD_WORKS:
        return "event road work";
      case SimEvent.CAR_STOPPED:
        return "event car stopped";
      case SimEvent.PEDESTRIAN_CROSSING:
        return "event pedestrian crossing";
      case SimEvent.ROAD_BLOCKED:
        return "event road blocked";
      case SimEvent.STOP_DRIVE:
        return "event stop drive";
      case SimEvent.HIGHWAY_EXIT:
        return "event highway exit";
      case SimEvent.RED_LIGHT:
        return "event red light";
      case SimEvent.UNEXPECTED_SCENARIO:
        return "event unexpected scenario";
      default: return "";
    }
  }

  getAudioFilePrefix(event: SimEvent){
    switch(event){
      case SimEvent.PEDESTRIAN_CROSSING:
        return 'event pedestrian crossing';
      case SimEvent.ROAD_WORKS:
        return 'event road work';
      case SimEvent.CAR_STOPPED:
        return 'event car stopped';
      case SimEvent.ROAD_BLOCKED:
        return 'event road blocked';
      case SimEvent.U_TURN:
        return 'event u turn';
      case SimEvent.TRAFFIC_JAM_REROUTE:
        return 'event traffic jam';
      case SimEvent.START_DRIVE:
        return 'event start drive';
      case SimEvent.STOP_DRIVE:
        return 'event stop drive';
      case SimEvent.UNEXPECTED_SCENARIO:
        return 'event unexpected scenario';
      case SimEvent.HIGHWAY_EXIT:
        return 'event highway exit';
      case SimEvent.RED_LIGHT:
        return 'event red light';
      // case SimEvent.HARD_WEATHER_CONDITIONS:
      //   return t('hard weather alert');
      // case SimEvent.EMERGENCY_VEHICLE:
      //   return t('emergency vehicle alert');
      default: return null;
    }
  }

  getEventText(event: SimEvent){
    const {t} = this.props;

    switch(event){
      case SimEvent.PEDESTRIAN_CROSSING:
        return t('event pedestrian crossing title');
      case SimEvent.ROAD_WORKS:
        return t('event road work title');
      case SimEvent.CAR_STOPPED:
        return t('event car stopped title');
      case SimEvent.ROAD_BLOCKED:
        return t('event road blocked title');
      case SimEvent.U_TURN:
        return t('event u turn title');
      case SimEvent.TRAFFIC_JAM_REROUTE:
        return t('event traffic jam title');
      case SimEvent.START_DRIVE:
        return t('event start drive title');
      case SimEvent.STOP_DRIVE:
        return t('event stop drive title');
      case SimEvent.UNEXPECTED_SCENARIO:
        return t('event unexpected scenario title');
      case SimEvent.HIGHWAY_EXIT:
        return t('event highway exit title');
      case SimEvent.RED_LIGHT:
        return t('event red light title');
      // case SimEvent.HARD_WEATHER_CONDITIONS:
      //   return t('hard weather alert');
      // case SimEvent.EMERGENCY_VEHICLE:
      //   return t('emergency vehicle alert');
      default: return "";
    }
  }

  getDescriptionText(event: SimEvent){
    const {t} = this.props;

    switch(event){
      case SimEvent.PEDESTRIAN_CROSSING:
        return t('event pedestrian crossing description');
      case SimEvent.ROAD_WORKS:
        return t('event road work description');
      case SimEvent.CAR_STOPPED:
        return t('event car stopped description');
      case SimEvent.ROAD_BLOCKED:
        return t('event road blocked description');
      case SimEvent.U_TURN:
        return t('event u turn description');
      case SimEvent.TRAFFIC_JAM_REROUTE:
        return t('event traffic jam description');
      case SimEvent.START_DRIVE:
        return t('event start drive description');
      case SimEvent.STOP_DRIVE:
        return t('event stop drive description');
      case SimEvent.UNEXPECTED_SCENARIO:
        return t('event unexpected scenario description');
      case SimEvent.HIGHWAY_EXIT:
        return t('event highway exit description');
      case SimEvent.RED_LIGHT:
        return t('event red light description');
      // case SimEvent.HARD_WEATHER_CONDITIONS:
      //   return t('hard weather alert');
      // case SimEvent.EMERGENCY_VEHICLE:
      //   return t('emergency vehicle alert');
      default: return "";
    }
  }

  componentDidMount(){
    requestAnimationFrame(this.displayCorrectSubtitle);
  }

  componentDidUpdate(prevProps: Props) {
    const {t} = this.props;

    if(prevProps.event !== this.props.event && this.state.showWarning && this.state.ttsEnabled){
      var filePrefix = this.getAudioFilePrefix(this.props.event);
      var titleFile = filePrefix?.replace(/\s/g, "-") + "-title.webm"
      var descriptionFile = filePrefix?.replace(/\s/g, "-") + "-description.webm"
      var warnText = t(this.getEventPrependTranslationValue(this.props.event)+' title');
      if(this.props.event != null && this.props.event.length > 0 && this.props.event !== SimEvent.NONE && warnText !== null && warnText.length > 0){
        this.audio = new Audio('audio/'+titleFile);
        this.readingDescription = false;
        this.audio.onended = () => {
          var descText = t(this.getEventPrependTranslationValue(this.state.event)+' description');
          if(this.state.ttsEnabled && this.state.showDescription && descText !== null && descText.length > 0){
            this.audio = new Audio('audio/'+descriptionFile);
            this.readingDescription = true;
            this.audio.play();
          }
        }
        this.audio.play();
      }
    }

    if(prevProps.event !== this.props.event){
      if(this.props.event != null && this.props.event.length > 0 && this.props.event !== SimEvent.NONE){
        this.currentSubtitle = t(this.getEventPrependTranslationValue(this.props.event)+' title').split(/(<[0-9]+>)/g);
        if(this.currentSubtitle.length > 0){
          this.subtitleIndex = 0;
          this.startedSubtitleAt = new Date().getTime();
          this.displayCorrectSubtitle();
        }
      } else {
        this.currentSubtitle = null;
      }
    }

    if(prevProps.mode !== this.props.mode || prevProps.event !== this.props.event)
      this.setState((prevState) => {
        return {
          ...prevState,
          event: this.props.event,
          mode: this.props.mode,
          showDescription: prevProps.mode !== this.props.mode ? (this.props.mode === CavInfoMode.HIGHINFO || this.props.mode === CavInfoMode.CUSTOMIZE) : prevState.showDescription
        }
      });
  }

  displayCorrectSubtitle(){
    if(this.currentSubtitle === null || this.currentSubtitle === undefined)
      return;

    if(this.subtitleIndex+1 < this.currentSubtitle.length && this.subtitleIndex+2 < this.currentSubtitle.length){
      if((this.currentSubtitle[this.subtitleIndex+1].slice(1, -1)*1000)+this.startedSubtitleAt <= new Date().getTime()){
        this.subtitleIndex += 2;
        this.startedSubtitleAt = new Date().getTime();
      }
    }

    if(this.currentSubtitle[this.subtitleIndex] !== this.state.currentSubtitleDisplayed){
      this.setState((prevState) => {
        return {
          ...prevState,
          currentSubtitleDisplayed: this.currentSubtitle[this.subtitleIndex]
        }
      }, () => {
        requestAnimationFrame(this.displayCorrectSubtitle);
      });
    } else {
      requestAnimationFrame(this.displayCorrectSubtitle);
    }
  }

  toggleTts(){
    this.props.dispatch(generateLog({surveyId: this.props.surveyId, timestamp: new Date().toString(), type: Type.BUTTON_TTS, value: (!this.state.ttsEnabled).toString() }));
    if(this.state.ttsEnabled)
      this.audio.pause();
      //window.speechSynthesis.cancel();
    this.setState((prevState) => {
      return {
        ...prevState,
        ttsEnabled: !prevState.ttsEnabled,
      }
    });
  }

  toggleWarning(){
    this.props.dispatch(generateLog({surveyId: this.props.surveyId, timestamp: new Date().toString(), type: Type.BUTTON_NOTIFICATION, value: (!this.state.showWarning).toString() }));
    if(this.state.ttsEnabled)
      this.audio.pause();
    this.setState((prevState) => {
      return {
        ...prevState,
        showWarning: !prevState.showWarning,
        showDescription: false
      }
    });
  }

  toggleDescription(){
    this.props.dispatch(generateLog({surveyId: this.props.surveyId, timestamp: new Date().toString(), type: Type.BUTTON_DESCRIPTION, value: (!this.state.showDescription).toString() }));
    if(this.readingDescription && this.state.ttsEnabled)
      this.audio.pause();

    this.setState((prevState) => {
      return {
        ...prevState,
        showWarning: !prevState.showDescription ? true : prevState.showWarning,
        showDescription: !prevState.showDescription
      }
    });
  }

  render(){
    const { t } = this.props;
    /*if(this.state.event === SimEvent.NONE)
      return(<div className="cav-info"></div>);
    else*/
      return(
        <>
          <div className="cav-warning">
            <div className="cav-warning-data">
              {this.state.showWarning && this.state.event !== SimEvent.NONE ? <h3>{this.state.currentSubtitleDisplayed}</h3> : <h3></h3> }
            </div>
            {this.state.mode === CavInfoMode.CUSTOMIZE ?
            <div className="cav-warning-controls">
              <div className="tts-button-holder" onClick={this.toggleTts}>{this.state.ttsEnabled ?
                <span className="material-icons">voice_over_off</span> : <span className="material-icons">record_voice_over</span>}
              </div>
              <button onClick={this.toggleWarning} className={this.state.showWarning ? 'active' : ''}>
                {this.state.showWarning ? t('notification disable') : t('notification enable')}
                {/*<span className="material-icons">warning</span>*/}
              </button>
            </div>
            : null }
          </div>
          <div className="cav-info">
            {this.state.mode === CavInfoMode.CUSTOMIZE ?
              <div className="cav-info-controls">

                <button onClick={this.toggleDescription} className={this.state.showDescription ? 'active' : ''}>
                  {this.state.showDescription ? t('description disable') : t('description enable')}
                  {/*<span className="material-icons">info</span>*/}
                </button>
              </div>
              : null}
              <div className="cav-info-data">
                {this.state.showDescription && this.state.event !== SimEvent.NONE ? <h4>{t(this.getEventPrependTranslationValue(this.state.event)+' description')}</h4> : <h4></h4> }
              </div>
          </div>
        </>
      );
  }
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>
export default withTranslation()(connector(CavInfo));
