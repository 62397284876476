import { Log } from '../actions/log';
import { logEndpoint, baseEndpoint, jwtToken, groupAssignationEndpoint } from './global';

export async function publishLog(logs: Array<Log>): Promise<boolean>{
  try {
    await fetch(baseEndpoint+logEndpoint, { method: 'POST', headers: { authorization: jwtToken, "content-type": "application/json" }, body: JSON.stringify(logs) });
    return true;
  } catch(err) {
    return false;
  }
}

export async function getAssignationGroup(): Promise<string|null>{
  try {
    let response = await fetch(baseEndpoint+groupAssignationEndpoint, { method: 'GET', headers: { authorization: jwtToken, "content-type": "application/json" } });
    return response.text();
  } catch(err) {
    return null;
  }
}