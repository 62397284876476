import React, {Component} from 'react';
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next';
import { RootState } from '../../../store/store';
import Blinkers from './car-components/blinkers';
import Clock from './car-components/clock';
import { actionUpdateCarData, CarDataAction } from '../../../actions/carData';
import Speed from './car-components/speed';
import CarNavigation from './car-components/car-navigation';
import MaxSpeed from './car-components/max-speed';
import Battery from './car-components/battery';

type CarDataState = {
  counter: number
}

function mapStateToProps(state: RootState) {
  return {
    currentState: state.setCurrentState.currentState,
    event: state.setCurrentEvent.event,
    carData: state.updateCarData,
  }
}

type Props = WithTranslation & PropsFromRedux;

class CarData extends Component<Props, CarDataState> {

  constructor(props: Props){
    super(props);
    this.state = {
      counter:0
    }
  }

  componentDidUpdate(prevProps: Props) {
  }


  render(){
    return(
      <div className="car-data">
        <div className="car-data-left">
          <div className="top-bar">
            <Blinkers status={this.props.carData.blinker?.direction}/>
            <Clock/>
          </div>
          <div className="main-content">
            <Speed speed={this.props.carData.speed.targetSpeed} transitionDuration={this.props.carData.speed.transitionDuration} />
            <div className="bottom-row">
              <Battery level={this.props.carData.batteryLevel.level} />
              <MaxSpeed maxSpeed={this.props.carData.maxSpeed.maxSpeed} />
            </div>
          </div>
        </div>
        <div className="car-data-right">
          <CarNavigation instruction={this.props.carData.gpsInstruction} eta={this.props.carData.gpsEta} />
        </div>
      </div>
    );
  }
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>
export default withTranslation()(connector(CarData));
