import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import Store from "./store/store";
import './index.css';
import './i18n';
import App from './App';

ReactDOM.render(
  <Provider store={Store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
