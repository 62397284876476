import { type } from "os";

export const UPDATE_CAR_DATA = "UPDATE_CAR_DATA";

export enum CarDataAction {
  BLINKER = "BLINKER",
  SPEED = "SPEED",
  MAX_SPEED = "MAX_SPEED",
  ROAD_TYPE = "ROAD_TYPE",
  VEHICLE_DETECTION = "VEHICLE_DETECTION",
  GPS_INSTRUCTION = "GPS_INSTRUCTION",
  GPS_ETA = "GPS_ETA",
  BATTERY_LEVEL = "BATTERY_LEVEL",
  MAP_VIEWER = "MAP_VIEWER"
}

export type BlinkerAction = {
  actionId: number,
  direction: "left"|"right"|"hazard"|"stop"
}

export type SpeedAction = {
  actionId: number,
  targetSpeed: number,
  transitionDuration: number
}

export type MaxSpeedAction = {
  actionId: number,
  maxSpeed: "30"|"50"|"70"|"80"|"90"|"110"|"120"|"130"|"unl"
}

export type RoadTypeAction = {
  actionId: number,
  laneAmount: 1|2|3,
  oppositeLaneAmount: 1|2|3,
  currentLane: 1|2|3,
  carTransition?: {
    fromLane: 1|2|3,
    transitionDuration: number
  }
  intersectionTransition?: {
    leftLaneAmount: 1|2|3,
    rightLaneAmount: 1|2|3,
    transitionDuration: number
  }
}

export type VehicleDetectionAction = {
  actionId: number,
  transitions: Array<{
    type: "static"|"coming-in"|"going-away"|"changing-lane"
    fromLane: 1|2|3,
    toLane?: 1|2|3,
    fromPosition: number, //Percentage
    toPosition: number, //Percentage
    duration: number
  }>
}

export type GpsInstructionAction = {
  actionId: number,
  type: "straight"|"left"|"right"|"left-slight"|"right-slight"|"reroute"|"none"|"destination",
  distance: number, //Meter
  duration: number //Time for distance decrease
}

export type GpsEtaAction = {
  actionId: number,
  duration: number,
  distance: number
}

export type BatteryLevel = {
  actionId: number,
  level: number
}

export type MapViewerAction = {
  actionId: number,
  segmentIndex: number,
  autoIncrement: number,
  enforceDuration: number,
  end: boolean
}

export type CarDataPayload = {
  action: CarDataAction,
  parameters: BlinkerAction|SpeedAction|MaxSpeedAction|RoadTypeAction|VehicleDetectionAction|GpsInstructionAction|GpsEtaAction|BatteryLevel|MapViewerAction
}

var actionId = 0;

export function actionUpdateCarData(payload: CarDataPayload){
  payload.parameters.actionId = actionId;
  actionId++;
  return {type: UPDATE_CAR_DATA, payload: payload};
}