import React, {Component} from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

type BlinkerProps = {
  status: "left"|"right"|"hazard"|"stop"
}

type BlinkerState = {
  status: "left"|"right"|"hazard"|"stop"
  leftBlinker: boolean;
  rightBlinker: boolean;
}

type Props = WithTranslation & BlinkerProps;

class Blinkers extends Component<Props, BlinkerState> {
  blinkingDelay = 0.650;
  private startedAt: number = Date.now();

  constructor(props: Props){
    super(props);
    this.state = {
      status: this.props.status,
      leftBlinker: false,
      rightBlinker: false
    }

    this.blinkerLogic = this.blinkerLogic.bind(this);
    this.refreshBlinkersIfNeeded = this.refreshBlinkersIfNeeded.bind(this);
    this.intermediateBlinkerRefresh = this.intermediateBlinkerRefresh.bind(this);
    this.initiateBlinkerRefresh = this.initiateBlinkerRefresh.bind(this);
  }

  componentDidUpdate(prevProps: Props) {
    if(prevProps.status !== this.props.status){
      if(this.props.status === "stop"){
        this.setState((prevState) => {return {...prevState, status: this.props.status, leftBlinker: false, rightBlinker: false}});
      } else {
        this.setState((prevState) => {return {...prevState, status: this.props.status, leftBlinker: false, rightBlinker: false}}, this.initiateBlinkerRefresh);
       }
    }
  }

  initiateBlinkerRefresh(){
    this.startedAt = Date.now();
    this.blinkerLogic();
    requestAnimationFrame(this.refreshBlinkersIfNeeded);
  }

  intermediateBlinkerRefresh(){
    this.startedAt = Date.now();
    requestAnimationFrame(this.refreshBlinkersIfNeeded);
  }

  refreshBlinkersIfNeeded(){
    if(Date.now() - this.startedAt < this.blinkingDelay * 1000){
      requestAnimationFrame(this.refreshBlinkersIfNeeded);
      return;
    }
    this.blinkerLogic();
  }

  blinkerLogic(){
    if(this.state.status === "stop"){
      this.setState((prevState) => {return {...prevState, leftBlinker: false, rightBlinker: false}});
    } else if(this.state.status === "left"){
      this.setState((prevState) => {return {...prevState, leftBlinker: !this.state.leftBlinker, rightBlinker: false}}, this.intermediateBlinkerRefresh);
    } else if(this.state.status === "right"){
      this.setState((prevState) => {return {...prevState, leftBlinker: false, rightBlinker: !this.state.rightBlinker}}, this.intermediateBlinkerRefresh);
    } else if(this.state.status === "hazard"){
      this.setState((prevState) => {return {...prevState, leftBlinker: !this.state.leftBlinker, rightBlinker: !this.state.rightBlinker}}, this.intermediateBlinkerRefresh);
    }
  }

  render(){
    return(
      <div className="blinkers">
        <div className={this.state.leftBlinker ? "blinker-left active" : "blinker-left"}>
          <div className="blinker-content"></div>
        </div>
        <div className={this.state.rightBlinker ? "blinker-right active" : "blinker-right"}>
          <div className="blinker-content"></div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Blinkers);
