import React, {Component} from 'react';
import './VideoViewer.css';
import ReactPlayer from 'react-player'
import { connect, ConnectedProps } from 'react-redux'
import { SimState, TRIGGER_STATE } from '../../actions/state';
import EventManager from '../../utils/EventManager';
import { RootState } from '../../store/store';

type VideoViewerProps = {
  onTimeProgress?: any;
}

type VideoViewerState = {
  onTimeProgress?: any;
  startOffset: number;
}

type AggregatedProps = VideoViewerProps & PropsFromRedux;

function mapStateToProps(state: RootState) {
  return {
    currentState: state.setCurrentState.currentState,
    event: state.setCurrentEvent.event,
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    dispatch: (action: any) => { dispatch(action) }
  }
}

class VideoViewer extends Component<AggregatedProps, VideoViewerState> {
  eventManager: EventManager;
  lastUpdate = 0;
  updateInterval = 100;

  constructor(props: AggregatedProps){
    super(props);
    this.state = {
      onTimeProgress: props.onTimeProgress,
      startOffset: 0
    };
    this.eventManager = new EventManager(this.state.startOffset);
    this.onProgress = this.onProgress.bind(this);
    this.onEnded = this.onEnded.bind(this);
  }

  componentDidMount(){
  }

  componentDidUpdate(prevProps: AggregatedProps) {
  }

  onProgress(progress: any){
    let currentTimeInMs = progress.playedSeconds * 1000;
    if((currentTimeInMs - this.lastUpdate) > this.updateInterval){
      this.lastUpdate = currentTimeInMs;
      const events = this.eventManager.processVideoTime(progress.playedSeconds);

      for(let payload of events){
        if(this.props.event !== payload) this.props.dispatch(payload);
      }

    }

    if(this.state.onTimeProgress != null)
      this.state.onTimeProgress(progress.playedSeconds);
  }

  onEnded(){
    this.props.dispatch({type: TRIGGER_STATE, payload: SimState.FINISHED});
  }

  render(){
    return <ReactPlayer url='https://www.youtube.com/watch?v=y7lJQPMyNNw'
    width="100%" height="100%" controls={false} playing={this.props.currentState === SimState.PLAY}
    config={{youtube: {playerVars: {start: this.state.startOffset}}}} onEnded={this.onEnded}
    muted={true} progressInterval={250} onProgress={this.onProgress} />
  }
}
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(VideoViewer);