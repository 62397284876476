import React, {Component} from 'react';
import './App.css';
import { withTranslation, WithTranslation } from 'react-i18next';
import VideoViewer from './components/video-viewer/VideoViewer';
import Intro from './components/intro/intro';
import Outro from './components/outro/outro';
import CavInfo, {CavInfoMode} from './components/hmi/cav-info/cavinfo';
import CarData from './components/hmi/car-data/cardata';
import FullScreenControl from './components/controls/fullscreen';
import { connect, ConnectedProps } from 'react-redux'
import { SimState } from './actions/state';
import { RootState } from './store/store';
import { generateLog, setSurveyId, Type } from './actions/log';
import { getAssignationGroup } from './api/log';
import MapViewer from './components/hmi/MapViewer';

type AggregatedProps = PropsFromRedux & WithTranslation;

function mapStateToProps(state: RootState) {
  return {
    currentState: state.setCurrentState.currentState as SimState,
  }
}

type AppState = {
  mode: CavInfoMode,
  privacy: string | null,
  hasSurveyIdError: boolean,
  hasGroupIdError: boolean,
  hasUnexistingGroupError: boolean,
  hasPrivacyError: boolean
}

class App extends Component<AggregatedProps, AppState> {
  constructor(props: AggregatedProps){
    super(props);
    this.state = {
      mode: CavInfoMode.CUSTOMIZE,
      privacy: null,
      hasSurveyIdError: false,
      hasGroupIdError: false,
      hasUnexistingGroupError: false,
      hasPrivacyError: false
    }
    this.retrieveSurveyIdFromUrl = this.retrieveSurveyIdFromUrl.bind(this);
    this.retrieveGroupAssignationFromUrl = this.retrieveGroupAssignationFromUrl.bind(this);
    this.getGroupAssignation = this.getGroupAssignation.bind(this);
  }

  componentDidMount() {
    let group = this.retrieveGroupAssignationFromUrl();
    let surveyId = this.retrieveSurveyIdFromUrl();
    let privacy = this.retrievePrivacyFromUrl();
    this.props.dispatch(generateLog({surveyId: surveyId, timestamp: new Date().toString(), type: Type.ASSIGNED_GROUP, value: group}));
  }

  retrieveSurveyIdFromUrl(): string{
    let queryParams = new URLSearchParams(window.location.search);
    let surveyId = queryParams.get('survey_id');

    if(surveyId !== null && surveyId !== undefined && surveyId.length > 0){
      console.log(surveyId);
      this.props.dispatch(setSurveyId(surveyId));

    } else {
      surveyId = "default-to-remove";
      this.setState((prevState) => {
        return {
          ...prevState,
          hasSurveyIdError: true
        }
      });
    }
      return surveyId;
  }

  retrieveGroupAssignationFromUrl(): CavInfoMode{
    let queryParams = new URLSearchParams(window.location.search);
    let group = queryParams.get('group');
    let groupEnumValue = CavInfoMode.CUSTOMIZE;

    if(group === null || group === undefined || group.length <= 0){
      this.setState((prevState) => {
        return{
          ...prevState,
          hasGroupIdError: true
        }
      });

      return groupEnumValue;
    }

    //TODO: Define group name in URL and match them.
    switch(group){
      case CavInfoMode.CUSTOMIZE:
        groupEnumValue = CavInfoMode.CUSTOMIZE;
        break;
      case CavInfoMode.LOWINFO:
        groupEnumValue = CavInfoMode.LOWINFO;
        break;
      case CavInfoMode.HIGHINFO:
        groupEnumValue = CavInfoMode.HIGHINFO;
        break;
      default:
        this.setState((prevState) => {
          return {
            ...prevState,
            hasUnexistingGroupError: true
          }
        });

        return groupEnumValue;
    }

    this.setState((prevState) => {
      return {
        ...prevState,
        mode: groupEnumValue
      }
    });

    return groupEnumValue;
  }

  retrievePrivacyFromUrl(): string {
    let queryParams = new URLSearchParams(window.location.search);
    let privacy = queryParams.get('priv');

    if(privacy !== null && privacy !== undefined && (privacy === "yes" || privacy === "no")){
      this.setState((prevState) => {
        return {
          ...prevState,
          privacy: privacy
        }
      });
    }
    else {
      privacy = "default-to-remove";
      this.setState((prevState) => {
        return {
          ...prevState,
          hasPrivacyError: true
        }
      });
    }

    return privacy;
  }

  async getGroupAssignation(){
    try{
      let assignationGroup = await getAssignationGroup();
      if(assignationGroup !== null){
        if (Object.values(CavInfoMode).some((mode: string) => mode === assignationGroup)){
          let group = assignationGroup as CavInfoMode;
          this.props.dispatch(generateLog({surveyId: "test", timestamp: new Date().toString(), type: Type.ASSIGNED_GROUP, value: assignationGroup}));
          this.setState((prevState) => {
            return {
              ...prevState,
              mode: group
            }
          })
        } else {
          //TODO
          console.log("DECIDE WHAT TO DO HERE");
        }
      } else {
        //TODO
        console.log("DECIDE WHAT TO DO HERE");
      }
    } catch {
      //TODO
      console.log("DECIDE WHAT TO DO HERE");
    }
  }

  onTimeProgress(playedSeconds: any){
    //console.log(playedSeconds);
  }

  errorComponent(txt: string){
    return (
      <div className='App'>
        <p>{txt}</p>
      </div>
    )
  }

  renderError(){
    const { t } = this.props;

    if(this.state.hasSurveyIdError)
      return this.errorComponent(t('error no id'));

    if(this.state.hasGroupIdError)
      return this.errorComponent(t('error no group'));

    if(this.state.hasUnexistingGroupError)
      return this.errorComponent(t('error wrong group'));

    if(this.state.hasPrivacyError)
      return this.errorComponent(t('error no privacy'));
  }

  renderPage(){
    return (
      <div className="App">
        <div className="overlay">
          {this.props.currentState === SimState.INIT ? <Intro/> : null}
          {this.props.currentState === SimState.FINISHED ? <Outro mode={this.state.mode} privacy={this.state.privacy}/> : null}
          <div className="controls">
            <FullScreenControl/>
          </div>
          <div className="bottom-hmi">
            <CarData/>
            <MapViewer lastIndex={0} />
            <CavInfo mode={this.state.mode}/>
          </div>
        </div>
        <div className="video">
          <VideoViewer onTimeProgress={this.onTimeProgress}/>
        </div>
      </div>
    )
  }

  render() {
    if(this.state.hasSurveyIdError || this.state.hasGroupIdError
      || this.state.hasUnexistingGroupError || this.state.hasPrivacyError)
      return this.renderError();
    return this.renderPage();
  }
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>
export default withTranslation()(connector(App));
