import { configureStore } from '@reduxjs/toolkit'
import setCurrentEvent from "../reducers/eventReducer";
import setCurrentState from "../reducers/stateReducer";
import updateCarData from "../reducers/carDataReducer";
import {handleLog} from "../reducers/logReducer";
import {throttle} from "lodash";
import { clearLogs as clearLogsAction } from "../actions/log";
import { publishLog } from "../api/log";

const reducers = {
  setCurrentEvent: setCurrentEvent,
  setCurrentState: setCurrentState,
  updateCarData: updateCarData,
  handleLog: handleLog
}

export type StoreType = typeof reducers;

const store = configureStore({reducer: reducers});

store.subscribe(throttle(async () => {
    var logs = store.getState().handleLog.logs
    if(logs.length === 0)
      return;
    var success = await publishLog(logs);
    if(success){
      store.dispatch(clearLogsAction(logs));
    }
  }, 1000));

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;