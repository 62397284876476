export const TRIGGER_EVENT = "TRIGGER_EVENT";

export enum SimEvent {
  INIT = "INIT",
  NONE = "NONE",
  PEDESTRIAN_CROSSING = "PEDESTRIAN_CROSSING",
  ROAD_WORKS = "ROAD_WORKS",
  CAR_STOPPED = "CAR_STOPPED",
  ROAD_BLOCKED = "ROAD_BLOCKED",
  U_TURN = "U_TURN",
  TRAFFIC_JAM_REROUTE = "TRAFFIC_JAM_REROUTE",
  // HARD_WEATHER_CONDITIONS = "HARD_WEATHER_CONDITIONS",
  // EMERGENCY_VEHICLE = "EMERGENCY_VEHICLE",
  START_DRIVE = "START_DRIVE",
  STOP_DRIVE = "STOP_DRIVE",
  HIGHWAY_EXIT = "HIGHWAY_EXIT",
  RED_LIGHT = "RED_LIGHT",
  UNEXPECTED_SCENARIO = "UNEXPECTED SCENARIO"
}

export function triggerEvent(payload: SimEvent){
  return {type: TRIGGER_EVENT, payload: payload};
}