import React, {Component} from 'react';
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next';
import { SimState, TRIGGER_STATE } from '../../actions/state';
import { RootState } from '../../store/store';
import { generateLog, Type } from '../../actions/log';

type AggregatedProps = PropsFromRedux & WithTranslation;

function mapStateToProps(state: RootState) {
  return {
    currentState: state.setCurrentState.currentState as SimState,
    surveyId: state.handleLog.surveyId as string
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    dispatch: (action: any) => { dispatch(action) }
  }
}

class Intro extends Component<AggregatedProps> {
  private once = true;
  constructor(props: AggregatedProps){
    super(props);
    this.dismissIntro = this.dismissIntro.bind(this);
  }

  dismissIntro(){
    this.props.dispatch({type: TRIGGER_STATE, payload: SimState.PLAY});
    if(this.once){
      this.props.dispatch(generateLog({surveyId: this.props.surveyId, timestamp: new Date().toString(), type: Type.BUTTON_START, value: "" }));
      this.props.dispatch(generateLog({surveyId: this.props.surveyId, timestamp: new Date().toString(), type: Type.SIM_START, value: "" }));
      this.once = false;
    }
  }

  render(){
    const { t } = this.props;
    return (
      <div className="intro-modal-holder">
        <div className="intro-modal">
          <h2>{t('intro modal title')}</h2>
          <div dangerouslySetInnerHTML={{ __html: t('intro modal content') }} />
          <button onClick={this.dismissIntro}>{t('intro modal ready button')}</button>
        </div>
      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>
export default withTranslation()(connector(Intro));