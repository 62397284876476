import React, {Component} from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

class FullScreenControl extends Component<WithTranslation> {
  constructor(props: WithTranslation){
    super(props);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }

  toggleFullscreen(){
    var elem: any = document.documentElement;
    var doc = document as any;
    if(doc.fullscreenElement == null){
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    } else {
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.webkitExitFullscreen) { /* Safari */
        doc.webkitExitFullscreen();
      } else if (doc.msExitFullscreen) { /* IE11 */
        doc.msExitFullscreen();
      }
    }
  }

  render(){
    return(
      <div className="control-fullscreen">
        <div className="material-icons" onClick={this.toggleFullscreen}>fullscreen</div>
      </div>
    );
  }
}

export default withTranslation()(FullScreenControl);
