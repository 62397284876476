import React, {Component} from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

type BlinkerProps = {
}

type BlinkerState = {
  currentTime: string
}

type Props = WithTranslation & BlinkerProps;

class Clock extends Component<Props, BlinkerState> {
  clockDelay = 1;

  constructor(props: Props){
    super(props);
    this.state = {
      currentTime: this.getCurrentTime()
    }

    this.updateCurrentTime = this.updateCurrentTime.bind(this);
    this.getCurrentTime = this.getCurrentTime.bind(this);
  }

  componentDidMount(){
    requestAnimationFrame(this.updateCurrentTime);
  }

  updateCurrentTime(){
    var currentTime = this.getCurrentTime();
    if(currentTime !== this.state.currentTime)
      this.setState((prevState) => {return {...prevState, currentTime: currentTime}});
    requestAnimationFrame(this.updateCurrentTime);
  }

  getCurrentTime() {
    var today = new Date();
    return ("0" + today.getHours()).slice(-2)+":"+("0" + today.getMinutes()).slice(-2);
  }

  render(){
    return(
      <div className="clock">
        <div className="currentTime">
          {this.state.currentTime}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Clock);
