import React, {Component} from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

type CarNavigationProps = {
  instruction: {
    type: "straight"|"left"|"right"|"left-slight"|"right-slight"|"reroute"|"none"|"destination",
    distance: number,
    duration: number
  };
  eta: {
    duration: number,
    distance: number
  };
}

type CarNavigationState = {
  currentInstruction: {
    type: "straight"|"left"|"right"|"left-slight"|"right-slight"|"reroute"|"none"|"destination",
    distance: number,
    duration: number,
    currentDisplayedDistance: string,
    startTime: number
  };
  currentEta: {
    duration: number,
    distance: number,
    currentDisplayedDistance: string,
    currentDisplayedDuration: string,
    startTime: number
  };
}

type Props = WithTranslation & CarNavigationProps;

class CarNavigation extends Component<Props, CarNavigationState> {
  renderEngine: NodeJS.Timeout|null;

  constructor(props: Props){
    super(props);
    this.state = {
      currentInstruction: {
        type: this.props.instruction.type,
        distance: this.props.instruction.distance,
        duration: this.props.instruction.duration,
        currentDisplayedDistance: this.formatDistance(this.props.instruction.distance),
        startTime: Date.now()
      },
      currentEta: {
        duration: this.props.eta.duration,
        distance: this.props.eta.distance,
        currentDisplayedDistance: this.formatDistance(this.props.eta.distance),
        currentDisplayedDuration: this.formatDuration(this.props.eta.duration),
        startTime: Date.now()
      }
    }

    this.cancelClock = this.cancelClock.bind(this);
    this.formatDistance = this.formatDistance.bind(this);
    this.formatDuration = this.formatDuration.bind(this);
    this.smoothRender = this.smoothRender.bind(this);
    this.renderEngine = null;
  }

  componentDidMount(){
    console.log("componentDidMount");
    requestAnimationFrame(this.smoothRender);
  }

  formatDistance(meters: number): string{
    if(meters > 2500)
      return Math.round(meters/100)/10+" km";
    else if(meters > 250)
      return Math.round(meters/100)*100+" m";
    else
      return Math.round(meters/10)*10+" m";
  }

  formatDuration(milliseconds: number): string{
    var raw = Math.floor(milliseconds/1000/60);
    return raw < 1 ? "< 1 min" : raw+" min";
  }

  formatDurationAlt(milliseconds: number): string{
    var raw = milliseconds/1000/60;
    var hours = Math.round(raw/60);
    var minutes = Math.round(raw%60);
    return ((hours < 10) ? "0"+hours : hours)+":"+((minutes  < 10) ? "0"+minutes : minutes);
  }

  smoothRender(){
    var currentTime = Date.now();
    var computedInstructionDistance: string;
    if(this.state.currentInstruction.duration > 0){
      var elapsedInstructionTime = currentTime - this.state.currentInstruction.startTime;
      var percentageInstructionElapsed = elapsedInstructionTime / this.state.currentInstruction.duration;
      computedInstructionDistance = this.formatDistance(Math.max(this.state.currentInstruction.distance*(1-percentageInstructionElapsed), 0));
    } else {
      computedInstructionDistance = this.formatDistance(this.state.currentInstruction.distance);
    }

    var computedEtaDistance: string;
    var computedEtaDuration: string;
    if(this.state.currentEta.duration > 0){
      var elapsedEtaTime = currentTime - this.state.currentEta.startTime;
      var percentageEtaElapsed = elapsedEtaTime / this.state.currentEta.duration;
      computedEtaDistance = this.formatDistance(Math.max(this.state.currentEta.distance*(1-percentageEtaElapsed), 0));
      computedEtaDuration = this.formatDuration(Math.max(this.state.currentEta.duration*(1-percentageEtaElapsed), 0));
    } else {
      computedEtaDistance = this.formatDistance(this.state.currentEta.distance);
      computedEtaDuration = this.formatDuration(this.state.currentEta.duration);
    }

    this.setState((prevState) => {
      if(prevState.currentEta.currentDisplayedDistance === computedEtaDistance && prevState.currentEta.currentDisplayedDuration === computedEtaDuration
        && prevState.currentInstruction.currentDisplayedDistance === computedInstructionDistance)
        return null;
      else
        return {
          ...prevState,
          currentEta:{
            ...prevState.currentEta,
            currentDisplayedDuration: computedEtaDuration,
            currentDisplayedDistance: computedEtaDistance
          },
          currentInstruction:{
            ...prevState.currentInstruction,
            currentDisplayedDistance: computedInstructionDistance
          }
        }
    });
    requestAnimationFrame(this.smoothRender);
  }

  cancelClock(){
    if(this.renderEngine != null)
      clearInterval(this.renderEngine);
    this.renderEngine = null;
  }

  componentDidUpdate(prevProps: Props){
    if(this.props.eta.distance !== prevProps.eta.distance || this.props.eta.duration !== prevProps.eta.duration
      || this.props.instruction.distance !== prevProps.instruction.distance || this.props.instruction.duration !== prevProps.instruction.duration
      || this.props.instruction.type !== prevProps.instruction.type){
      this.setState((prevState) => {
        return {
          ...prevState,
          currentEta:{
            ...prevState.currentEta,
            duration: this.props.eta.duration,
            distance: this.props.eta.distance,
            currentDisplayedDistance: this.formatDistance(this.props.eta.distance),
            currentDisplayedDuration: this.formatDuration(this.props.eta.duration),
            startTime: Date.now()
          },
          currentInstruction:{
            ...prevState.currentInstruction,
            type: this.props.instruction.type,
            duration: this.props.instruction.duration,
            distance: this.props.instruction.distance,
            currentDisplayedDistance: this.formatDistance(this.props.instruction.distance),
            currentDisplayedDuration: this.formatDuration(this.props.instruction.duration),
            startTime: Date.now()
          }
        }
      }, () => {
      });
    }
  }

  componentWillUnmount() {
    this.cancelClock();
  }

  render(){
    const { t } = this.props;
    return(
      <div className="car-navigation">
        <div className="eta">
        {this.state.currentInstruction.type === 'destination' ?
          <div className="eta-title">{t('arrived text')}</div>
          :
          <>
            <div className="eta-title">{t('arrival text')}</div>
            <div className="eta-details">
                <div className="eta-duration">{this.state.currentEta.currentDisplayedDuration}</div>
                <div className="eta-distance">{this.state.currentEta.currentDisplayedDistance}</div>
            </div>
          </>
        }
        </div>
        <div className="instructions">
              <>
                <div className="instruction-arrow">
                  {this.state.currentInstruction.type === 'straight' ? <img src="icons/straight.svg"/> : null}
                  {this.state.currentInstruction.type === 'left-slight' ? <img src="icons/slight-left.svg"/> : null}
                  {this.state.currentInstruction.type === 'left' ? <img src="icons/left.svg"/> : null}
                  {this.state.currentInstruction.type === 'right-slight' ? <img src="icons/slight-right.svg"/> : null}
                  {this.state.currentInstruction.type === 'right' ? <img src="icons/right.svg"/> : null}
                  {this.state.currentInstruction.type === 'reroute' ? <div className="lds-dual-ring"></div> : null}
                  {this.state.currentInstruction.type === 'destination' ? <img src="icons/reached.svg"/> : null}
                </div>
                {this.state.currentInstruction.type !== 'reroute' && this.state.currentInstruction.type !== 'destination' ? <div className="instruction-distance">{this.state.currentInstruction.currentDisplayedDistance}</div> : null }
              </>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CarNavigation);
