import { actionUpdateCarData, CarDataAction, CarDataPayload } from "../actions/carData";
import { SimEvent, triggerEvent } from "../actions/event";
import { SimState, triggerState } from "../actions/state";
var jsonData = require('../data/test.json');

export default class EventManger {
  startOffset: number;

  constructor(startOffset: number){
    this.startOffset = startOffset;
    jsonData.events.sort(
      (a: { timestamp: number; }, b: { timestamp: number; }) => a.timestamp - b.timestamp
    );
  }

  lastId : number = 0;

  processVideoTime(currentTime: number){
    currentTime = currentTime - this.startOffset;
    var currentEvent = jsonData.events[this.lastId];

    if(currentEvent == null ||
      currentTime * 1000 < currentEvent.timestamp) return [];

    this.lastId++;

    var events : Array<{type: string, payload: CarDataPayload|SimEvent|SimState}> = [];
    for(let event of currentEvent.events){
      switch(event.eventType){
        case "carData":
          var carAction = null;
          var carParameter = null;
          var carData : CarDataPayload | null = null;

          switch(event.actionType){
            case "blinker":
              carAction = CarDataAction.BLINKER
              carParameter = {
                actionId: -1,
                direction: event.actionValue.direction
              }

              carData = {
                action: carAction,
                parameters: carParameter
              }

              events.push(actionUpdateCarData(carData));
              break;

            case "speed":
              carAction = CarDataAction.SPEED
              carParameter = {
                actionId: -1,
                targetSpeed: event.actionValue.targetSpeed,
                transitionDuration: event.actionValue.transitionDuration
              }

              carData = {
                action: carAction,
                parameters: carParameter
              }

              events.push(actionUpdateCarData(carData));
              break;

            case "maxSpeed":
              carAction = CarDataAction.MAX_SPEED
              carParameter = {
                actionId: -1,
                maxSpeed: event.actionValue.maxSpeed
              }

              carData = {
                action: carAction,
                parameters: carParameter
              }

              events.push(actionUpdateCarData(carData));
              break;

            case "roadType":
              carAction = CarDataAction.ROAD_TYPE
              carParameter = {
                actionId: -1,
                laneAmount: event.actionValue.laneAmount,
                oppositeLaneAmount: event.actionValue.oppositeLaneAmount,
                currentLane: event.actionValue.currentLane,
                carTransition: event.actionValue.carTransition,
                intersectionTransition: event.actionValue.intersectionTransition
              }

              carData = {
                action: carAction,
                parameters: carParameter
              }

              events.push(actionUpdateCarData(carData));
              break;

            case "vehicleDetection":
              carAction = CarDataAction.VEHICLE_DETECTION
              carParameter = {
                actionId: -1,
                transitions: event.actionValue.transitions
              }

              carData = {
                action: carAction,
                parameters: carParameter
              }

              events.push(actionUpdateCarData(carData));
              break;

            case "gpsInstruction":
              carAction = CarDataAction.GPS_INSTRUCTION
              carParameter = {
                actionId: -1,
                type: event.actionValue.type,
                distance: event.actionValue.distance,
                duration: event.actionValue.duration
              }

              carData = {
                action: carAction,
                parameters: carParameter
              }

              events.push(actionUpdateCarData(carData));
              break;

            case "gpsEta":
              carAction = CarDataAction.GPS_ETA
              carParameter = {
                actionId: -1,
                distance: event.actionValue.distance,
                duration: event.actionValue.duration
              }

              carData = {
                action: carAction,
                parameters: carParameter
              }

              events.push(actionUpdateCarData(carData));
              break;

            case "batteryLevel":
              carAction = CarDataAction.BATTERY_LEVEL
              carParameter = {
                actionId: -1,
                level: event.actionValue.level
              }

              carData = {
                action: carAction,
                parameters: carParameter
              }

              events.push(actionUpdateCarData(carData));
              break;

            case "mapViewer":
              carAction = CarDataAction.MAP_VIEWER
              carParameter = {
                actionId: -1,
                segmentIndex: event.actionValue.segmentIndex,
                autoIncrement: event.actionValue.autoIncrement === undefined ? 0 : event.actionValue.autoIncrement,
                enforceDuration: event.actionValue.enforceDuration === undefined ? 0 : event.actionValue.enforceDuration,
                end: event.actionValue.end === undefined ? false : event.actionValue.end,
              }

              carData = {
                action: carAction,
                parameters: carParameter
              }

              events.push(actionUpdateCarData(carData));
              break;

            default:
              // Do nothing
              break;
          }
          break;

        case "state":
          switch(event.actionType){
            case "init":
              events.push(triggerState(SimState.INIT));
              break;
            case "play":
              events.push(triggerState(SimState.PLAY));
              break;
            case "pause":
              events.push(triggerState(SimState.PAUSE));
              break;
            case "finished":
              events.push(triggerState(SimState.FINISHED));
              break;
            default:
              // Do nothing
              break;
          }
          break;

        case "event":
          switch(event.actionType){
            case "init":
              events.push(triggerEvent(SimEvent.INIT));
              break;
            case "pedestrianCrossing":
              events.push(triggerEvent(SimEvent.PEDESTRIAN_CROSSING));
              break;
            case "roadWorks":
              events.push(triggerEvent(SimEvent.ROAD_WORKS));
              break;
            case "carStopped":
              events.push(triggerEvent(SimEvent.CAR_STOPPED));
              break;
            case "roadBlocked":
              events.push(triggerEvent(SimEvent.ROAD_BLOCKED));
              break;
            case "uTurn":
              events.push(triggerEvent(SimEvent.U_TURN));
              break;
            case "trafficJamReroute":
              events.push(triggerEvent(SimEvent.TRAFFIC_JAM_REROUTE));
              break;
            // case "hardWeatherConditions":
            //   events.push(triggerEvent(SimEvent.HARD_WEATHER_CONDITIONS));
            //   break;
            // case "emergencyVehicle":
            //   events.push(triggerEvent(SimEvent.EMERGENCY_VEHICLE));
            //   break;
            case "startDrive":
              events.push(triggerEvent(SimEvent.START_DRIVE));
              break;
            case "stopDrive":
              events.push(triggerEvent(SimEvent.STOP_DRIVE));
              break;
            case "unexpectedScenario":
              events.push(triggerEvent(SimEvent.UNEXPECTED_SCENARIO));
              break;
            case "highwayExit":
              events.push(triggerEvent(SimEvent.HIGHWAY_EXIT));
              break;
            case "redLight":
              events.push(triggerEvent(SimEvent.RED_LIGHT));
              break;
            case "none":
              events.push(triggerEvent(SimEvent.NONE));
              break;
            default:
              // Do nothing
              break;
          }
          break;

        default:
          // Do nothing
          break;
      }
    }

    return events;
  }
}